.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* Base Styling */
body, html, #root, .h-100 {
  height: 100%;
  background-color: #f4f9f7;  /* base light green */
  font-family: 'Bahnschrift', sans-serif;
}
/* Navbar */
.navbar {
  background-color: #e6f7e9; /* Light green shade */
  border-bottom: 1px solid #d4e5d7;  /* subtle border for separation */
}

.navbar-brand {
  color: #333 !important;
}

/* Sidebar */
.bg-light {
  background-color: #f4f9f7;  /* Base light green */
  border-right: 1px solid #d4e5d7;  /* subtle border for separation */
  padding-top: 15px;
}

/* Nav Pills */
.nav-pills .nav-link {
  color: #333;
  border-radius: 0;  /* make it rectangle for a more modern look */
}

.nav-pills .nav-link.active, .nav-pills .nav-link:hover {
  color: white;
  background-color: #99d5b7;  /* Slightly muted green */
}

/* Content */
.tab-content {
  padding: 20px;
}

.card-img-top {
  width: 100%;        /* Set the width to 100% of the parent element */
  height: 200px;      /* Fixed height for all images */
  object-fit: cover;  /* This will make sure images cover the area without stretching */
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* src/index.css or src/App.css */
@import "~bootstrap/dist/css/bootstrap.min.css";

